import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import { ApiSelectOption } from "api/common/ApiSelectOption";
import { CurrenciesList } from "types/index";
import { FavoritesListsStoreObject } from "types/favoritesLists";
import { FavoritesListSupplier } from "api/supplier-database/favoritesList";

export type BookingPlatformStoreState = {
    backUrl: string;
    backUrlPropertyReports: string;
    data: any[];
    currentSupplierId: string /*it replace the router query supplier type identification*/;
    jumpVisibilityState: boolean;
    initLoadingHotelComponent: boolean;
    hiddenList: boolean;
    currenciesList: CurrenciesList["data"];
    favorites: FavoritesListsStoreObject[];
    /*newlyCreatedFavoritesList scope: advisor, no matter what supplier was added from */
    newlyCreatedFavoritesList: FavoritesListSupplier[];
    // set to true when on booking confirmation page to inform hotels list if we need to reset backURL
    isBackFromBookingConfirmationPage: boolean;
    priceFilter: { min: number; max: number };
    mapMarkersCount: number;
    favoritesCount: number | null;
    propertyReportsCount: { supplierId: string; total: null | number }[];
};

export type BookingPlatformStoreActions = {
    setBackUrl: (value: string) => void;
    setBackUrlPropertyReports: (value: string) => void;
    setFavoritesCount: (value: number | null) => void;
    setData: (value: any[]) => void;
    setCurrentSupplierId: (value: string) => void;
    setJumpVisibilityState: (value: boolean) => void;
    setInitLoadingHotelComponent: (value: boolean) => void;
    setHiddenList: (value: boolean) => void;
    setCurrenciesList: CurrenciesList["setData"];
    setFavoritesList: (supplierId: string, lists: FavoritesListSupplier[]) => void;
    deleteFavoritesListFromStore: (
        listId: string
    ) => void /* name deleteFavoritesList already use for API function */;
    /* setNewlyCreatedFavoritesList is planned to use for adding/removing newlyCreatedFavoritesList.
     scope: advisor, no matter what supplier was added from */
    setNewlyCreatedFavoritesList: (value: FavoritesListSupplier[]) => void;
    clearStore: () => void;
    setIsBackFromBookingConfirmationPage: (value: boolean) => void;
    setPriceFilter: (value: { min?: number; max?: number }) => void;
    setMapMarkersCount: (value: number) => void;
    setPropertyReportsCount: (supplierId: string, total: null | number) => void;
};

export const initialState: BookingPlatformStoreState = {
    backUrl: "",
    backUrlPropertyReports: "",
    data: [],
    currentSupplierId: "",
    jumpVisibilityState: false,
    initLoadingHotelComponent: false,
    hiddenList: false,
    currenciesList: [],
    favorites: [],
    isBackFromBookingConfirmationPage: false,
    priceFilter: {
        min: 0,
        max: 0
    },
    newlyCreatedFavoritesList: [],
    mapMarkersCount: 0,
    favoritesCount: null,
    propertyReportsCount: [{ supplierId: "", total: null }]
};

const BookingPlatformStore = (set: any, get: any) => ({
    ...initialState,
    setPropertyReportsCount: (supplierId: string, total: null | number) =>
        set((state: BookingPlatformStoreState) => ({
            propertyReportsCount: [{ supplierId, total }]
        })),
    setBackUrl: (value: string) => set(() => ({ backUrl: value })),
    setBackUrlPropertyReports: (value: string) => set(() => ({ backUrlPropertyReports: value })),
    setIsBackFromBookingConfirmationPage: (value: boolean) =>
        set(() => ({
            isBackFromBookingConfirmationPage: value
        })),
    setPriceFilter: (value: { min?: number; max?: number }) =>
        set((state: BookingPlatformStoreState) => ({
            priceFilter: { ...state.priceFilter, ...value }
        })),
    setData: (data: string[]) => set(() => ({ data })),
    setCurrentSupplierId: (value: string) =>
        set(() => ({
            currentSupplierId: value
        })),
    setJumpVisibilityState: (value: boolean) =>
        set(() => ({
            jumpVisibilityState: value
        })),
    setInitLoadingHotelComponent: (value: boolean) =>
        set(() => ({
            initLoadingHotelComponent: value
        })),
    setHiddenList: (value: boolean) =>
        set(() => ({
            hiddenList: value
        })),
    setCurrenciesList: (value: ApiSelectOption[]) =>
        set(() => ({
            currenciesList: value
        })),
    setFavoritesList: (supplierId: string, lists: FavoritesListSupplier[]) =>
        set((state: BookingPlatformStoreState) => {
            let favorites: FavoritesListsStoreObject[] = [];
            if (state.favorites.find(item => item.supplierId === supplierId)) {
                favorites = [...state.favorites].map(storedFavorite => {
                    if (storedFavorite.supplierId === supplierId) {
                        /*rewrite exist favoritesLists from user selected/unselected lists or deleted lists*/
                        let updatedFavoritesLists = [...storedFavorite.favoritesLists].map(
                            storedList => {
                                const updatedList = lists.find(item => item.id === storedList.id);

                                return updatedList || storedList;
                            }
                        );
                        /* find new elements from the provided user list and insert next to default "My favorites" list */
                        const newLists = lists.filter(
                            item => !storedFavorite.favoritesLists.find(el => el.id === item.id)
                        );

                        if (lists.length < updatedFavoritesLists.length && !newLists.length) {
                            /*case if user get a new list from API with deleted lists or the lists were deleted on My lists page*/
                            const removedFavoritesLists = updatedFavoritesLists.filter(
                                item => !lists.find(removedItem => removedItem.id === item.id)
                            );
                            updatedFavoritesLists = updatedFavoritesLists.filter(
                                item =>
                                    !removedFavoritesLists.find(
                                        removedItem => removedItem.id === item.id
                                    )
                            );
                        }

                        updatedFavoritesLists.splice(1, 0, ...newLists);

                        return {
                            supplierId,
                            favoritesLists: updatedFavoritesLists
                        };
                    } else {
                        return storedFavorite;
                    }
                });
            } else {
                favorites = [...state.favorites];
                favorites.splice(1, 0, {
                    supplierId,
                    favoritesLists: lists
                });
            }
            return {
                favorites
            };
        }),
    setFavoritesCount: (value: number | null) => set(() => ({ favoritesCount: value })),
    deleteFavoritesListFromStore: (listId: string) =>
        set((state: BookingPlatformStoreState) => {
            let favorites: FavoritesListsStoreObject[] = [...state.favorites].map(favoriteObj => ({
                ...favoriteObj,
                favoritesLists: favoriteObj.favoritesLists.filter(list => list.id !== listId)
            }));

            return {
                favorites
            };
        }),
    setNewlyCreatedFavoritesList: (value: FavoritesListSupplier[]) =>
        set((state: BookingPlatformStoreState) => ({
            newlyCreatedFavoritesList: value
        })),
    setMapMarkersCount: (value: number) =>
        set(() => ({
            mapMarkersCount: value
        })),
    clearStore: () => set(initialState)
});

export const useBookingsPlatformStore = create<
    BookingPlatformStoreState & BookingPlatformStoreActions
>()(
    devtools(
        persist(
            BookingPlatformStore,

            {
                name: "bookingPlatformStore",
                storage: createJSONStorage(() => sessionStorage)
            }
        )
    )
);
